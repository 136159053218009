import React from "react"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackosIcon from '@material-ui/icons/ArrowBackIos';

export default function Video ({ videoSrcURL, videoTitle, arrowRightClick, arrowLeftClick, ...props })
{

    return(
        <div className="iframe-container">

            <div className={'arrowLeft'} onClick={() => {
                arrowLeftClick();
            }}><ArrowBackosIcon className={'gallery-icon'}/></div>
            <iframe
                src={videoSrcURL}
                title={videoTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />
            <div className={'arrowRight'} onClick={() => {
                arrowRightClick();
            }}><ArrowForwardIosIcon className={'gallery-icon'}/></div>

        </div>
    );

};