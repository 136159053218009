import React, {useState, useEffect, useCallback} from 'react';
import {uiSelector} from 'state/ui';
import {makeStyles} from '@material-ui/core/styles';
import Instagram from '../../assets/images/icons-insta-white.svg';
import Facebook from '../../assets/images/facebook-logo-white.svg';
import linkedin from '../../assets/images/icons-linkedin-white.svg';

const useStyles = makeStyles((theme) => ({
    root: {},
}));


export default function SocialInside({ styling }) {

    // Handle loading more articles
    useEffect(() => {

    }, [])

    return (
        <>
        <div className="social-share">

            <ul className="social text-right">
                <li>
                    <a href="https://www.instagram.com/freshpatisserie/?hl=el" target="_blank">
                        <span className={'instagramIcon'}></span>
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/freshpatisserie.gr/" target="_blank">
                        <span border="0" className={'facebookIcon'}></span>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/freshpatisserie/?originalSubdomain=gr" target="_blank">
                        <span border="0" className={'linkedinIcon'}></span>
                    </a>
                </li>
            </ul>
            <p className="text-end">follow us on social media</p>

        </div>
        </>
    );
};
