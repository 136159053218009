import React, {useState, useEffect, useCallback} from 'react';
import { uiSelector } from 'state/ui';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Video from '../../components/Video';
import CancelIcon from '@material-ui/icons/Cancel';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.85)'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    close:{
        textAlign: 'right',
        cursor: 'pointer',
        marginRight: '-18px',
        color: '#fff'
    },
    videoActive:{
        borderBottom: 'thick solid #fff !important',
        opacity: '1 !important'
    },
    lines:{
        display: 'list-style',
        textAlign: 'center',
        '& li':{
            width: '72px',
            height: '4px',
            borderBottom: ' thick solid #fff',
            display: 'inline-flex',
            marginLeft: '10px',
            opacity: '0.5'
        }
    },

}));

export default function VideoGallery({
        items,
        currentIndex,
        currentVideo,
        close
    }){

    // Handle loading more articles
    useEffect(() => {
        setCurrentIndexGallery(parseInt(currentIndex));
        setCurrentVideoPlay(currentVideo);
    }, [])

    const classes = useStyles();
    const rootRef = React.useRef(null);
    const [currentIndexGallery, setCurrentIndexGallery ] = useState();
    const [ currentVideoPlay, setCurrentVideoPlay ] = useState();

    const arrowRight = useCallback(() => {
        let whatIndex = parseInt(currentIndexGallery) + 1;

        if(whatIndex > 0){

            setCurrentIndexGallery(parseInt(whatIndex));

            setCurrentVideoPlay( items[whatIndex].videos );

        }

    }, [setCurrentIndexGallery,setCurrentVideoPlay,items, currentIndex, currentIndexGallery]);

    const arrowLeft = useCallback(() => {
        let whatIndex = parseInt(currentIndexGallery) - 1;

        if(whatIndex < items.length){

            setCurrentIndexGallery(parseInt(whatIndex));

            setCurrentVideoPlay( items[whatIndex].videos );

        }

    }, [setCurrentIndexGallery,setCurrentVideoPlay,items, currentIndex, currentIndexGallery]);

    const lineClicked = (whatIndex) => {

        setCurrentIndexGallery(parseInt(whatIndex));

        setCurrentVideoPlay( items[whatIndex].videos );

    }

    return (
        <>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                container={() => rootRef.current}
            >
                <div className="container">

                    <div className="row">

                        <div className="col">

                            <ul className={classes.lines} >

                            {
                                items.map((items, index) => {

                                    return(
                                        <li style={{ cursor: 'pointer'}} onClick={() => { lineClicked(index)}} className={(currentIndexGallery === index) ? `${classes.videoActive}` : ``}></li>
                                    );

                                })
                            }

                            </ul>

                            <div className={classes.close} onClick={()=>{
                                close();
                            }}><CancelIcon /></div>

                            {console.log('current video', currentVideoPlay)}

                            {
                                (currentVideoPlay) &&
                                <Video
                                    videoSrcURL={currentVideoPlay?.videourl}
                                    videoTitle={currentVideoPlay?.videotitle}
                                    arrowRightClick={arrowRight}
                                    arrowLeftClick={arrowLeft}
                                />
                            }

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
