import React, {useState, useEffect} from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image from "gatsby-image";
import {Link, Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";
import Video from '../components/Video';
import VideoGallery from '../components/VideoGallery';
import StoresLocation from '../components/StoresLocation';
import parse from "html-react-parser";
import Social from '../components/Social';
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import SocialInside from "../components/FooterInside";
import Flickity from "react-flickity-component";

const flickityOptions = {
    cellAlign: 'center',
    wrapAround: true,
    pageDots: false,
    imagesLoaded: false,
    contain: true,
    autoPlay: 2000,
    arrowShape: 'M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z'
}

const AboutPage: React.FC = ({location, data: {
    allWpLocation,
    allWpPage,
    allWpVideo
}}) => {

    const {t} = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(true);
    const {language, changeLanguage} = useI18next();

    let pageDetailsFull;

    let videos = allWpVideo?.nodes;

    let editors;

    let mission;

    //  Check language
    if (language !== 'en') {

        pageDetailsFull = allWpPage?.nodes[0]?.pageDetails;

        editors = allWpPage?.nodes[0]?.editors;

        mission = allWpPage?.nodes[0]?.missionAndVision;

    }else{

        pageDetailsFull = allWpPage?.nodes[0]?.translations[0]?.pageDetails;

        editors = allWpPage?.nodes[0]?.translations[0]?.editors;

        mission = allWpPage?.nodes[0]?.translations[0]?.missionAndVision;

    }

    // State for the list
    const [list, setList] = useState([...videos.slice(0, 4)]);

    // State to trigger oad more
    const [loadMore, setLoadMore] = useState(false)

    // State of whether there is more to load
    const [hasMore, setHasMore] = useState(videos.length > 4)

    // Load more button click
    const handleLoadMore = () => {
        setLoadMore(true);
    }

    const [showGallery, setShowGallery ] = useState(false);
    const [currentVideo, setCurrentVideo ] = useState(false);
    const [currentIndex, setCurrentIndex ] = useState(false);

    // Handle loading more articles
    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < videos.length
            const nextResults = isMore
                ? videos.slice(currentLength, currentLength + 3 )
                : []
            setList([...list, ...nextResults]);
            setLoadMore(false);
        }
    }, [loadMore, hasMore]) //eslint-disable-line

    //Check if there is more
    useEffect(() => {
        const isMore = list.length < videos.length
        setHasMore(isMore)
    }, [list]);

    const clickVideo = (event, video, index) =>{

        event.preventDefault();

        setCurrentVideo(video);

        setCurrentIndex(index);

        setShowGallery(true);

    }

    const closeGallery = () => {

        setShowGallery(false);

        return

    }

    return (
        <Layout>

            <SEO title="About" />
            <div className="body-wrap">

                <section className="content">

                    <div className="container ">

                        {
                            ((isDesktop || isTablet))
                                ?
                                <div className="row">

                                    <div className="col-9">
                                        <h1 style={{ paddingLeft: '33%'}}>
                                            {
                                                (pageDetailsFull) && pageDetailsFull?.title
                                            }
                                        </h1>
                                    </div>
                                    <div className="col-3">
                                        <SocialInside styling={'mobileVersion'}/>
                                    </div>

                                </div>
                                :
                                <div className="row">

                                    <div className="row">

                                        <h1>{pageDetailsFull?.title}</h1>

                                    </div>

                                </div>
                        }

                        <div className="row">

                            <div className="col videoMainAbout">

                                <Video
                                    videoSrcURL={pageDetailsFull?.videoUrlPageDetails}
                                    videoTitle="Fresh"
                                />

                            </div>

                        </div>

                        <div className="row justify-content-center">

                            <div className="col-lg-8 col-sm-12 mt-4 pt-4 text-center titleDescription">

                                <h2>{pageDetailsFull?.titleH3}</h2>
                                <p>{pageDetailsFull?.titleH3Description}</p>

                            </div>

                        </div>

                        <div className="row mt-5">

                            <div className="page-content">

                                {
                                    (mission?.missionAndVision) &&
                                    parse(mission?.missionAndVision)
                                }

                            </div>

                        </div>

                        <div className="row mt60">

                            <div className="col section-title">

                                <h2>{editors?.editorsTitle}</h2>

                            </div>

                        </div>

                        <div className="row">

                            <div className="col">

                                <Image
                                    fluid={editors?.editorsMainImage?.localFile?.childImageSharp?.fluid}
                                    alt={editors?.editorsMainImage?.localFile?.altText}
                                    className="img-fluid full"
                                />

                            </div>

                        </div>

                        <div className="row mt-5">

                            <div className="page-content">

                                {
                                    (editors?.editorsContent) &&
                                    parse(editors?.editorsContent)
                                }

                            </div>

                        </div>

                    </div>

                </section>

                {/* EXPLORE VIDEOS */}
                <section className="explore">

                    {
                        (!isMobile) &&
                        <div className="container">

                            <div className="row">

                                <div className="col section-title text-end">

                                    <h2>exlpore fresh</h2>

                                </div>

                            </div>

                            <div className="row">

                                {

                                    list.map((video, index) => {

                                        return(
                                            <div className={`col-lg-3 col-sm-12 slidingGalleryMain ${(index > 3) ? 'mt-3' : ''}`}>
                                                <a onClick={(event)=>{
                                                    clickVideo(event, video?.videos, index);
                                                }} href={ video.videos.videourl } className="hover-link">
                                                    <div className="thumbwrap">
                                                        <Image
                                                            fluid={video?.videos?.videoimage?.localFile?.childImageSharp?.fluid}
                                                            alt={video?.videos?.videoimage?.localFile?.altText}
                                                            className="img-fluid full"
                                                        />
                                                        <span className="more">PLAY</span>
                                                    </div>
                                                    <h3>{(language !== 'en') ? video?.videos.videotitle : video?.translations[0]?.videos?.videotitle }</h3>
                                                    <p>{ (language !== 'en') ? video?.videos.videodescription.substring(0, 150) + '...' : video?.translations[0]?.videos?.videodescription.substring(0, 150) + '...' }</p>
                                                </a>
                                            </div>

                                        );

                                    })

                                }

                                {
                                    (showGallery)
                                        ? <VideoGallery close={closeGallery} currentVideo={currentVideo} currentIndex={currentIndex} items={list} />
                                        : ''
                                }

                            </div>

                        </div>
                    }

                    {
                        (isMobile) &&
                        <div className="container-fluid">

                            <div className="row">

                                <div className="col section-title text-start">

                                    <h2>exlpore fresh</h2>

                                </div>

                            </div>

                            <div className="row">

                                <Flickity
                                    className={'slider'} // default ''
                                    elementType={'div'} // default 'div'
                                    options={flickityOptions} // takes flickity options {}
                                    disableImagesLoaded={true} // default false
                                    reloadOnUpdate // default false
                                    static // default false
                                >

                                    {

                                        list.map((video, index) => {

                                            return(

                                                <div className="slide">
                                                    <div className="thumbwrap">
                                                        <a  onClick={(event)=>{
                                                            clickVideo(event, video?.videos, index);
                                                        }} href={ video.videos.videourl } className="hover-link slidingGallery">
                                                            <div className="thumbwrap">
                                                                <Image
                                                                    fluid={video?.videos?.videoimage?.localFile?.childImageSharp?.fluid}
                                                                    alt={video?.videos?.videoimage?.localFile?.altText}
                                                                    className="img-fluid full"
                                                                />
                                                                <span className="more">PLAY</span>
                                                            </div>
                                                            <h3>{(language !== 'en') ? video?.videos.videotitle : video?.translations[0]?.videos?.videotitle }</h3>
                                                            <p>{ (language !== 'en') ? video?.videos.videodescription.substring(0, 150) : video?.translations[0]?.videos?.videodescription.substring(0, 150) }</p>
                                                        </a>
                                                    </div>
                                                    {/*<h3>{item?.caption}</h3>*/}
                                                    {/*<p>{ (item.description) ? parse(item.description.replace(/(<([^>]+)>)/gi, "").substring(0, 40) + "...") : null}</p>*/}
                                                </div>

                                            );

                                        })

                                    }

                                </Flickity>

                                {
                                    (showGallery)
                                        ? <VideoGallery close={closeGallery} currentVideo={currentVideo} currentIndex={currentIndex} items={list} />
                                        : ''
                                }

                            </div>

                        </div>
                    }

                    {/*{*/}
                        {/*(hasMore)&&*/}
                        {/*<div className="row mt-5">*/}

                            {/*<div id="scrollMore" className="col text-center">*/}

                                {/*<button onClick={()=>{ handleLoadMore() }} className="load-more">SHOW MORE</button>*/}

                            {/*</div>*/}

                        {/*</div>*/}
                    {/*}*/}

                </section>

                {/* STORES */}
                <StoresLocation location={location} locations={allWpLocation} language={language} />

            </div>

        </Layout>
    );
};

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allWpLocation {
        nodes {
          locationInformation {
            address
            city
            latitude
            longitude
            telephone
            directions
          }
          translations{
            locationInformation {
              address
              city
              latitude
              longitude
              telephone
              directions
            }
          }
        }
      },
      allWpVideo(
        sort: {fields: [date], order: DESC}
        filter: {language: {code: {eq: EL}}}
      ) {
        nodes {
          videos {
            videodescription
            videotitle
            videourl
            videoimage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 280, maxHeight:420, fit: COVER, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                }
              }
            }
          },
          translations {
            videos {
              videodescription
              videotitle
              videoimage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 280, maxHeight:420, fit: COVER, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                    }
                  }
                }
            }
          }
        }
      }
      allWpPage(filter: {databaseId: {eq: 659}}) {
    nodes {
      pageDetails {
        title
        titleH3
        titleH3Description
        titleSection
        archiveShowMorePosts
        videoUrlPageDetails
      }
      editors {
        editorsContent
        editorsTitle
        editorsMainImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 880, maxHeight:420, fit: COVER, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
            }
          }
        }
      },
      missionAndVision {
        missionAndVision
      },
      translations{
        pageDetails {
          title
          titleH3
          titleH3Description
          titleSection
          archiveShowMorePosts
          videoUrlPageDetails
        }
        editors {
          editorsContent
          editorsTitle
          editorsMainImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 880, maxHeight:420, fit: COVER, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                }
              }
            }
        }
      }
    }
  }
  }
`;